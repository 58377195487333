.container{
    width:300px;
    height: 500px;
    /* border:1px solid teal; */
    text-align: center;
    line-height: 2.5rem;
}

.imgdiv{
    height: 60%;
    width: 70%;
    /* border: 1px solid black; */
    margin:auto;
    display: flex;
    margin: auto;
}
.img{
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    margin:auto
}
.title{
    width:90%;
    text-overflow: ellipsis;
    overflow: hidden;
white-space: nowrap;
padding: 1rem;

}
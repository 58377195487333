@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

body{
  position: relative;
}
.menu{
  position:absolute;
  width:100%;
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
#clear{
    /* color:red; */
}
.banner {
  width: 100vw;
  height: 100vh;

  background-position: 50% 50%;
}

nav {
  
  z-index: 1;
  width: 100%;

  /* background: #242526; */
}
#horizontal-line{
    /* height: 20px; */
    /* border: 1px solid #000;
     */
     /* position: relative; */
        /* top: 20px; */
        border: none;
        height: 1px;
        width: 70%;
        background: rgb(136, 136, 136);
        /* margin-bottom: 50px; */
}
nav .wrapper {
  position: relative;
  max-width: 1800px;
  padding: 0px 30px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
}
.wrapper .logo a {
  color: #f2f2f2;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.wrapper .nav-links {
  display: inline-flex;
}

.nav-links{
  display:flex;
  /* border:4px solid  black; */
  width: 70%;
  justify-content: space-around;
}

.nav-links li {
  list-style: none;

 /* border:5px solid rgb(168, 163, 163); */

}
#bold{
    font-weight: 400;
    /* font-size: 14px; */
}
.nav-links li a {
  color:white;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
 
}



.nav-links li a:hover {
  /* background: #3a3b3c; */
  /* color: #7cdbee; */
  /* text-decoration: underline; */
  /* background-color:#974d62 */
}
.nav-links .mobile-item {
  display: none;
}
.nav-links .drop-menu {
  position: absolute;
  background: #242526;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
#navs__links li:hover .drop-menu,
#navs__links li:hover .mega-box {
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.mega-box {
  position: absolute;
  left: 0;
  width: 90%;
  /* height:600px; */
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  margin-top: 0.6rem;
  /* z-index: 100; */
}
.mega-box .content {
  background: white;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  margin-top:-30px;
  justify-content: space-between;
  text-align:"center"
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.15); */
}
#megas .content{
    width: 100%;
    padding-left:350px;
}
.mega-box .content .row {
  width: calc(60% - 80px);
  line-height: 1.5rem;
  display: flex;
  flex-direction:column;
  justify-content: top;
  font-size: 14px;
}

.content .row header {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}
.content .row header:hover{
    /* color: #7cdbee; */
    text-decoration: underline;

}
#col{
  color:rgb(137, 233, 246);
}
.content .row .mega-links {
  margin-left: -40px;
  /* border-left: 1px solid rgba(255, 255, 255, 0.09); */

  /* margin-top:-200px; */
  /* justify-content:none; */
}
.row .mega-links li {
  padding: 0 20px;
  /* margin-bottom:500px; */
}
.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  display: block;
  /* margin-bottom: 20px; */
}
.row .mega-links li a:hover {
  color: #7cdbee;
}
.wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}

@media (max-width: 970px) {
  .wrapper .btn {
    display: none
  }
  
  .wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: 0%;
    background: #242526;
    display: none;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: hidden;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3a3b3c;
  }
  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
    overflow-y: hidden;
  }
  #close-btn:checked ~ .btn.menu-btn {
    display: block;
    overflow-y: hidden;
  }
  .nav-links li {
    margin: 15px 10px;
  }
  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
    overflow-y: hidden;
  }
  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    overflow-y: hidden;
    transition: all 0.3s ease;
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box {
    max-height: 100%;
  }
  /* .nav-links .desktop-item {
    display: none;
  } */

}
nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div {
  font-size: 45px;
  font-weight: 600;
}
/* 
#line-height-new{
  margin-bottom: -200px;
}
#line-height-men{
  margin-bottom:890px;
}
#line-height-new__2{
  margin-bottom:800px;
}

#line-height-new{
  margin-bottom:1000px;
} */

/* .desktop-item{
  display: block;
} */

